import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-barometrics',
  templateUrl: './barometrics.component.html',
  styleUrls: ['./barometrics.component.css']
})
export class BarometricsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
