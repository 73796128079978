import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})

export class EmailListService {
  
  constructor( private firestore: AngularFirestore ) { }

  addEmail(name, data) {
    return new Promise<any>((resolve, reject) =>{
        this.firestore
            .collection("emailList")
            .doc(name).set(data)
            .then(res => {}, err => reject(err));
    });
  }

  getAllEmails() {
    return this.firestore.collection("emailList").snapshotChanges();
  }

  checkForEmail(email) {
    let query = this.firestore.collection("emailList").snapshotChanges().subscribe(val => console.log(val));
  }

}

