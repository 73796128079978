import { Component, OnInit } from '@angular/core';

declare var Amplitude: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})

export class AboutComponent implements OnInit {

  constructor( ) {
  }

  ngOnInit() {
    Amplitude.init({
      "songs": [
        {
          "name": "on the table",
          "artist": "Hemlock for Socrates",
          "album": "Paper Animals",
          "url": "../../assets/audio/01onthetable.mp3",
        }, 
        {
          "name": "supply",
          "artist": "Hemlock for Socrates",
          "album": "Paper Animals",
          "url": "../../assets/audio/06supply.mp3",
        },
        {
          "name": "productivity",
          "artist": "Hemlock for Socrates",
          "album": "Paper Animals",
          "url": "../../assets/audio/08productivity.mp3",
        },
        {
          "name": "swarm",
          "artist": "Hemlock for Socrates",
          "album": "Paper Animals",
          "url": "../../assets/audio/12swarm.mp3",
        }
      ]
    });
  }

  

}
