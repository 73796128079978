import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EmailListService } from '../../shared/email-list.service';
import { EmailRecord } from '../../shared/email-record';

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.css']
})
export class EmailListComponent implements OnInit {

  allEmails: EmailRecord[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailListService: EmailListService
  ) { }

  ngOnInit() {
    
    this.emailListService.getAllEmails().subscribe(data => {
      this.allEmails = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as EmailRecord;
      })
    })

  }



}
