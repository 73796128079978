import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminHomeComponent } from './admin-home/admin-home.component';
import { EmailListComponent } from './email-list/email-list.component';
import { EventEditorComponent } from './event-editor/event-editor.component';
import { EventListComponent } from './event-list/event-list.component';

const adminRoutes: Routes = [
    {
        path: 'admin',
        component: AdminHomeComponent,
        children: [
            {
                path: 'email-list',
                component: EmailListComponent
            }, {
                path: 'event-list',
                component: EventListComponent
            }, {
                path: 'event-editor/:id',
                component: EventEditorComponent,

            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(adminRoutes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
