import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faYoutube, faBandcamp, faSoundcloud, faSpotify } from '@fortawesome/free-brands-svg-icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { NgxLoadingModule } from 'ngx-loading';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
import { DateTimePickerModule} from 'ngx-datetime-picker';

import { environment } from '../../environments/environment';

import { AdminHomeComponent } from './admin-home/admin-home.component';
import { EmailListComponent } from './email-list/email-list.component';
import { EventEditorComponent } from './event-editor/event-editor.component';
import { EventListComponent } from './event-list/event-list.component';

@NgModule({
  declarations: [
    AdminHomeComponent,
    EmailListComponent,
    EventEditorComponent,
    EventListComponent
  ],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AdminRoutingModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    DatetimePopupModule.forRoot(),
    DateTimePickerModule,
    NgbModule,
    NgbCollapseModule,
    NgxAudioPlayerModule,
    BrowserAnimationsModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
  ]
})
export class AdminModule {
  constructor() {
    library.add(faFacebook, faBandcamp, faSoundcloud, faYoutube, faSpotify);
  }
 }
