import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { BarometricsComponent } from './barometrics/barometrics.component';
import { ContactComponent } from './contact/contact.component';
import { EventsComponent } from './events/events.component';
import { HomeComponent } from './home/home.component';
import { PicturesComponent } from './pictures/pictures.component';
import { WaveformsComponent } from './waveforms/waveforms.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'about'
  }, {
    path: 'about',
    component: AboutComponent
  }, {
    path: 'barometrics',
    component: BarometricsComponent
  }, {
    path: 'contact',
    component: ContactComponent
  }, {
    path: 'events',
    component: EventsComponent
  }, {
    path: 'login',
    component: LoginComponent
  }, {
    path: 'home',
    component: AboutComponent
  }, {
    path: 'pictures',
    component: PicturesComponent
  }, {
    path: 'waveforms',
    component: WaveformsComponent
  }, {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
