import { Component, OnInit } from '@angular/core';

import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'HEMLOCK for SOCRATES';
  toggleNavbar = true;
  splashActive = true;
  faSignInAlt = faSignInAlt;

  constructor() {}

  ngOnInit() {

    $(window).scroll(function(e) {

      // add/remove class to navbar when scrolling to hide/show
      var scroll = $(window).scrollTop();
      if (scroll >= 150) {
          $('.navbar').addClass("navbar-hide");
      } else {
          $('.navbar').removeClass("navbar-hide");
      }
  
  });

  }
}
