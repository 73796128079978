import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { EventListService } from '../../shared/event-list.service';
import { EventRecord } from '../../shared/event-record';

declare var $: any;

@Component({
  selector: 'app-event-editor',
  templateUrl: './event-editor.component.html',
  styleUrls: ['./event-editor.component.css']
})
export class EventEditorComponent implements OnInit {

  public currentEventId: string;
  public eventForm: FormGroup;

  isMeridian = true;
  showSpinners = false;
  date: Date = new Date();

  event: EventRecord;

  constructor(
    private eventListService: EventListService, 
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.eventForm = this.fb.group({
      eventName: new FormControl(''),
      eventSubName: new FormControl(''),
      eventDate: new FormControl(''),
      eventTime: new FormControl(''),
      venueName: new FormControl(''),
      venueLink: new FormControl(''),
      venueMapLink: new FormControl(''),
      cityName: new FormControl(''),
      stateName: new FormControl(''),
      note: new FormControl('')
    });

  }

  // Use getters for cleaner HTML code
  get eventName() {
    return this.eventName.get('eventName')
  }

  get eventSubName() {
    return this.eventName.get('eventSubName')
  }

  get eventDate() {
    return this.eventDate.get('eventDate')
  }

  get eventTime() {
    return this.eventTime.get('eventTime')
  }

  get venueName() {
    return this.venueName.get('venueName')
  }

  get venueLink() {
    return this.venueLink.get('venueLink')
  }

  get venueMapLink() {
    return this.venueLink.get('venueMapLink')
  }

  get cityName() {
    return this.cityName.get('cityName')
  }

  get stateName() {
    return this.stateName.get('stateName')
  }

  get note() {
    return this.note.get('note')
  }

  onSubmit() {

    let eventHours = new Date(this.eventForm.value.eventTime).getHours();
    let eventMinutes = new Date(this.eventForm.value.eventTime).getMinutes();
    let convertedEventTime = new Date(this.eventForm.value.eventDate).setHours(eventHours);
    convertedEventTime = new Date(convertedEventTime).setMinutes(eventMinutes);
    convertedEventTime = new Date(convertedEventTime).setSeconds(0);
    let newEventDateTime = new Date(convertedEventTime);
    
    console.log("Converted event time is: " + newEventDateTime + ", in numbers it's " + convertedEventTime);

    //let ngbDate = this.eventForm.value.eventDate;
    //let newDate = new Date(ngbDate.year, ngbDate.month-1, ngbDate.day);

    let newEventRecord = {
      eventName: this.eventForm.value.eventName,
      eventSubName: this.eventForm.value.eventSubName,
      eventDateTime: convertedEventTime,
      venueName: this.eventForm.value.venueName,
      venueLink: this.eventForm.value.venueLink,
      venueMapLink: this.eventForm.value.venueMapLink,
      cityName: this.eventForm.value.cityName,
      stateName: this.eventForm.value.stateName,
    }

    this.eventListService.addEvent(newEventRecord).then(res => {
      return res;
    });


    this.eventForm.reset();

    $("#submitModal").modal('show');

  }

}
