import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';

import { EventListService } from '../../shared/event-list.service';
import { EventRecord } from 'src/app/shared/event-record';


@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {

  allEvents: EventRecord[];
  allPastEvents: EventRecord[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventListService: EventListService
  ) { }

  ngOnInit() {

    this.eventListService.getAllEventsByDate().subscribe(data => {
      this.allEvents = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as EventRecord;
      })
    })

    this.eventListService.getAllPastEvents().subscribe(data => {
      this.allPastEvents = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as EventRecord;
      })
    })

  }

}
