import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})

export class EventListService {

  today: number = new Date().getTime();

  constructor(private firestore: AngularFirestore ) { }

  addEvent(data) {
    return new Promise<any>((resolve, reject) =>{
        this.firestore
            .collection('eventList')
            .add(data)
            .then(res => {}, err => reject(err));
    });
  }

  updateEvent(id, data) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
      .collection('eventList')
      .doc(id)
      .set(data)
      .then(res => {}, err => reject(err));
    }
    )
  }

  getEvent(id){
    return this.firestore.collection('eventList').doc(id).snapshotChanges();
  }

  getAllEvents() {
    return this.firestore.collection('eventList').snapshotChanges();
  }

  getAllEventsByDate() {
    return this.firestore
    .collection('eventList', ref => ref.orderBy('eventDateTime'))
    .snapshotChanges();
  }

  getAllPastEvents() {
    console.log(this.today);
    return this.firestore
    .collection('eventList', ref => ref.orderBy('eventDateTime', 'desc').startAfter(this.today))
    .snapshotChanges();
  }

  getAllFutureEvents() {
    return this.firestore
    .collection('eventList', ref => ref.orderBy('eventDateTime').startAfter(this.today))
    .snapshotChanges();
  }

}
