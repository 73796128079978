import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';

import { EmailListService } from '../shared/email-list.service';
//import { EmailValidator } from '../shared/email-validator';

declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {

  constructor(
    private emailListService: EmailListService,
    private firestore: AngularFirestore
  ) { }

  emailForm = new FormGroup({
    email: new FormControl('', [

      //TODO: Why doesn't validator work?

      
      Validators.required, 
      Validators.email,
      //EmailValidator.email(this.firestore)

    ]),
    firstName: new FormControl(''),
    lastName: new FormControl('')
  })

  ngOnInit() {
  }

  // Use getters for cleaner HTML code
  get email() {
    return this.emailForm.get('email')
  }

  get firstName() {
    return this.emailForm.get('firstName')
  }

  get lastName() {
    return this.emailForm.get('lastName')
  }

  onSubmit() {

    console.log("This part works...");

    let newName = this.emailForm.value.lastName + this.emailForm.value.firstName;
    let newEmailRecord = {
      email: this.emailForm.value.email,
      firstName: this.emailForm.value.firstName,
      lastName: this.emailForm.value.lastName
    }


    //TODO: Why doesn't form clear?

    this.emailListService.addEmail(newName, newEmailRecord).then(res => {
      return res;
    });

    this.emailForm.reset();

    $("#submitModal").modal('show');

    console.log("got all the way through...");

  }

}
